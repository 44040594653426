import fetchAllFeaturedProducts from "@api/operations/fetch-all-featured-products";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { NextSeo } from "next-seo";
import Router from "next/router";
import React from "react";

import Container from "@components/Container";
import Hero from "@components/Hero";
import MainLayout from "@components/MainLayout";
import PaginationWithResultsCount from "@components/PaginationWithResultsCount";
import ProductCardGrid from "@components/ProductCardGrid";
import Spacer from "@components/Spacer";

export const getServerSideProps: GetServerSideProps = async ({ req, query }) => {
  const { page } = query;
  const { products, pageInfo } = await fetchAllFeaturedProducts({
    pageRequest: {
      page: page ? parseInt(page as string) - 1 : 0,
      size: 6,
      direction: "ASC",
      sort: "id",
    },
  });

  return {
    props: { products, pageInfo },
  };
};

export type IndexPropsType = InferGetServerSidePropsType<typeof getServerSideProps>;

export default function Home({ products, pageInfo }: IndexPropsType) {
  return (
    <>
      <NextSeo title="Genie" description="Genie." />
      <Hero />
      <Spacer size="md" />
      <Container>
        <h1>Featured</h1>
        <Spacer size="sm" />
        {products.length > 0 ? (
          <>
            <ProductCardGrid products={products} />
            {pageInfo && pageInfo.totalPages > 1 && (
              <>
                <Spacer size="md" />
                <PaginationWithResultsCount
                  perPage={pageInfo.size}
                  itemCount={parseInt(pageInfo.totalElements)}
                  page={pageInfo.page}
                  onPageChange={(value) => {
                    Router.push(`${Router.pathname}?page=${value + 1}`);
                  }}
                />
              </>
            )}
          </>
        ) : (
          <span>No featured items.</span>
        )}
        <style jsx>{`
          h1 {
            font-size: 30px;
            font-weight: 500;
          }
        `}</style>
      </Container>
    </>
  );
}

Home.Layout = MainLayout;
